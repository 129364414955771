// calculates potential height of an element based of it content's height
// (used when initial height is set to zero)
export function potentialHeight(element) {
  if (!element) return
  let height
  const initialHeight = element.offsetHeight
  element.style.height = "auto"
  height = element.offsetHeight
  element.style.height = initialHeight + "px"
  return height
}

// calculates proportional value in a given range of minimal and maximal viewport width
export function fluidSizing(minWidth, maxWidth, minValue, maxValue) {
  const widthDiff = maxWidth - minWidth
  const valueDiff = maxValue - minValue
  return `calc(${minValue}px + ${valueDiff} * ((100vw - ${minWidth}px) / ${widthDiff}))`
}

export function slugify(string) {
  if (typeof string === "undefined") {
    return ""
  }

  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}
