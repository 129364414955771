import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import useScrollListener from "src/hooks/useScrollListener"
import useWindowSize from "src/hooks/useWindowSize"
import { fluidSizing } from "src/utils/helpers"
import { animateScroll } from "react-scroll"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import { PrimaryTitle } from "src/components/Typography"
import AiMap from "src/assets/images/inline-svg/ai-map-full.svg"
import { get } from "lodash"

const initialMapScale = 1.4
const timelineBarPointHeight = 70

export const pageQuery = graphql`
  {
    strapiHistory {
      text {
        header
        left_paragraph
        right_paragraph
      }
      event {
        year
        text
        images {
          url
          mime
        }
      }
      team_picture {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 584, quality: 80, layout: FIXED)
          }
        }
      }
      team_picture_caption
    }
  }
`

const HistoryPage = ({ data }) => {
  const history = data.strapiHistory
  const [observed, setObserved] = useState(false)
  const [currentEventIndex, setCurrentEventIndex] = useState(-1)
  const currentEventIndexRef = useRef(currentEventIndex)
  currentEventIndexRef.current = currentEventIndex
  const mapWrapper = useRef()
  const mapPoints = useRef()
  const timeline = useRef()
  const events = useRef()
  const timelineBar = useRef()
  const eventHeight = useWindowSize().height
  let timelineTopOffset = 9000

  const countries = [
    "denmark",
    "poland",
    "lithuania",
    "uk",
    "norway",
    "ukraine",
    "belarus",
    "russia",
    "estonia",
    "france",
    "bulgaria"
  ]

  const countryActivations = [0, 5, 9, 10, 11, 12, 12, 13, 13, 15, 17]
  const mapPointsActivations = [1, 7]

  function showEvent(index, prevIndex) {
    mapPointsActivations.forEach((activation, mapPointIndex) => {
      const mapPoint = mapPoints.current.childNodes[mapPointIndex]
      const mapPointVisible = index === activation
      const mapPointScale = mapPointVisible
        ? 2
        : index < 1
        ? prevIndex === 0
          ? 7
          : 0
        : 0

      mapPoint.style.transform = `scale(${mapPointScale})`
      mapPoint.style.opacity = mapPointVisible ? 1 : 0
      if (index === activation - 1) {
        setTimeout(() => {
          mapPoint.style.transform = `scale(7)`
        }, 700)
      }
    })

    countries.forEach((country, countryIndex) => {
      const path = document.getElementById(`path-${country}`)
      if (index >= countryActivations[countryIndex]) {
        if (!path.classList.contains("-active")) {
          path.classList.add("-active")
        }
      } else {
        if (path.classList.contains("-active")) {
          path.classList.remove("-active")
        }
      }
    })
  }

  function handleScroll() {
    const tPosition =
      window.scrollY + window.innerHeight - timelineTopOffset - 200
    const cIndex =
      window.scrollY <= 30 ? -1 : Math.floor(tPosition / eventHeight)
    const progress = tPosition / eventHeight
    const tbFixed = tPosition + 200 > window.innerHeight / 2

    if (progress < history.event.length - 1 && cIndex >= 0) {
      const mapScale = Math.max(
        initialMapScale - (initialMapScale - 1) * (Math.max(progress, 0) / 7),
        1
      )
      const tbOffset = -parseInt(
        (timelineBarPointHeight / eventHeight) * tPosition
      )
      const imageOffset = parseInt(eventHeight * 0.3 * (progress - cIndex))

      const image = events.current.childNodes[cIndex].childNodes[2]
      image && (image.style.transform = `translateY(${imageOffset}px)`)

      if (cIndex <= 7) {
        mapWrapper.current.firstChild.style.transform = `scale(${mapScale})`
      }
      if (tbFixed) {
        !timelineBar.current.classList.contains("-fixed") &&
          timelineBar.current.classList.add("-fixed")
      } else {
        timelineBar.current.classList.contains("-fixed") &&
          timelineBar.current.classList.remove("-fixed")
      }
      timelineBar.current.style.transform = `translateY(${tbOffset}px)`
    }

    if (cIndex !== currentEventIndexRef.current) {
      showEvent(cIndex, currentEventIndexRef.current)
      setCurrentEventIndex(cIndex)
    }
  }

  function navigateTo(index) {
    const scrollTop = events.current.childNodes[index].offsetTop + 600
    animateScroll.scrollTo(scrollTop, {
      duration: 900,
      smooth: "easeOutQuart",
    })
  }

  function timelineBarClick(index) {
    navigateTo(index)
  }

  useEffect(() => {
    setObserved(true)
    /* eslint-disable react-hooks/exhaustive-deps */
    timelineTopOffset = timeline.current.offsetTop
  }, [])

  useScrollListener({
    callback: handleScroll,
  })

  return (
    <Wrapper
      data-event={currentEventIndex}
      className={observed ? "-observed" : ""}
    >
      <Seo
        title="History of Anima International - For a future free from animal suffering"
        description="See how we have been developing and working in new countries since 2000. We operate in those countries and areas of Europe where animals need help most."
      />
      <MapWrapper ref={mapWrapper}>
        <MapInner>
          <Map />
        </MapInner>
        <MapPoints ref={mapPoints}>
          <MapPoint>
            <MapPointIcon name="avatar-1" />
          </MapPoint>
          <MapPoint>
            <MapPointIcon name="avatar-2" />
          </MapPoint>
        </MapPoints>
      </MapWrapper>
      <Container>
        <Inner>
          <Intro>
            <Title
              as="h1"
              dangerouslySetInnerHTML={{ __html: history.text.header }}
            />
            <Columns>
              <Column>
                <Paragraph>{history.text.left_paragraph}</Paragraph>
              </Column>
              <Column>
                <Paragraph>{history.text.right_paragraph}</Paragraph>
              </Column>
            </Columns>
          </Intro>
          <ScrollIndicatorWrapper>
            <ScrollIndicator>
              <ScrollIndicatorIcon name="chevron-down" source="feather" />
              Learn our story
            </ScrollIndicator>
          </ScrollIndicatorWrapper>
          <Timeline ref={timeline}>
            <Events ref={events}>
              {history.event.map(({ year, text, images }, index) => {
                const details = text.replace(
                  /<strong>(.*?)<\/strong>/g,
                  "<em><span>$1</span></em>"
                )
                return (
                  <Event
                    className={index === currentEventIndex ? "-active" : ""}
                    key={index}
                  >
                    <EventYear>{year}</EventYear>
                    <EventDetails
                      dangerouslySetInnerHTML={{ __html: details }}
                    />
                    {images.length > 0 && (
                      <EventImageWrapper>
                        <EventImageInner>
                          {images.map(({ url, mime }, imageIndex) => {
                            const isPhoto = mime === "image/jpeg"
                            return (
                              <EventImage
                                isPhoto={isPhoto}
                                src={url}
                                key={imageIndex}
                                alt={year + ' photo'}
                              />
                            )
                          })}
                        </EventImageInner>
                      </EventImageWrapper>
                    )}
                  </Event>
                )
              })}
              <EndEvent>
                <EventImageFixed
                  image={get(
                    history.team_picture,
                    "localFile.childImageSharp.gatsbyImageData"
                  )}
                  alt="Team"
                />
                <EndEventCaption>
                  {history.team_picture_caption}
                </EndEventCaption>
              </EndEvent>
            </Events>
            <TimelineBar ref={timelineBar}>
              {history.event.map(({ year }, index) => (
                <TimelineBarPoint
                  className={index === currentEventIndex ? "-active" : ""}
                  key={index}
                  onClick={() => {
                    timelineBarClick(index)
                  }}
                >
                  {year}
                </TimelineBarPoint>
              ))}
            </TimelineBar>
          </Timeline>
        </Inner>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 225px;
`

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0.5);
  transition: opacity 0.5s;
  will-change: opacity;

  [data-event="-1"] & {
    opacity: 0;
  }

  ${media.tablet} {
    transform: none;
  }
`

const MapInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(${initialMapScale});
  will-change: transform;
  transition: transform 0.1s linear;
`

const Map = styled(AiMap)`
  width: 4600px;
  /* height: 2254.11px; */
  max-width: none;
  position: absolute;
  top: calc(50% - 1127px + 500px);
  left: calc(50% - 2300px + 40px);
  z-index: -1;
  user-select: none;
  transform-origin: 50% 26%;
  will-change: transform;
  transition: opacity 0.35s, transform 1s ${(p) => p.theme.easing.easeOutQuart};

  .country-ai {
    transition: 0.25s;

    &.-active {
      stroke-width: 0.25px;
    }
  }

  .country-ai {
    [data-event="14"] &.-active,
    [data-event="15"] &.-active,
    [data-event="16"] &.-active,
    [data-event="17"] &.-active,
    [data-event="18"] &.-active  {
      fill: #5fd1cf;
      stroke: #fff;
      stroke-width: 0.15px;
    }
  }

  .country-anima {
    &.-active {
      fill: #f5e0e4;
      stroke: #d1667f;
    }
  }

  .country-open-cages {
    &.-active {
      fill: #e4f8f8;
      stroke: ${(p) => p.theme.color.primary};
    }
  }

  .country-near {
    [data-event="6"] & {
      fill: url(#dots-pattern);
    }
  }

  [data-event="0"] & {
    #path-denmark.-active {
      transition-delay: 0.5s;
    }
  }

  [data-event="14"] &,
  [data-event="15"] &,
  [data-event="16"] &,
  [data-event="17"] &,
  [data-event="18"] & {
    transform: translateX(140px) scale(0.9);
  }

  [data-event="16"] &,
  [data-event="17"] &,
  [data-event="18"] &  {
    #path-belarus.-active {
      fill: #fff;
    }
  }

  [data-event="17"] &,
  [data-event="18"] & {
    #path-russia.-active,
    #path-lithuania.-active,
    #path-estonia.-active {
      fill: #fff;
    }
  }

  [data-event="18"] & {
    opacity: 0.1;
  }
`

const MapPoints = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;

  ${media.tablet} {
    display: block;
  }
`

const MapPoint = styled.div`
  opacity: 0;
  width: 44px;
  height: 44px;
  overflow: hidden;
  position: absolute;
  transform: scale(7);
  background: #fff;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0 16px 16px rgba(3, 34, 33, 0.1);
  transition: 0.7s ${(p) => p.theme.easing.easeOutQuart};
  will-change: opacity, transform;

  &:nth-child(1) {
    top: -45px;
    left: 30px;
  }

  &:nth-child(2) {
    top: 35px;
    left: 138px;
  }
`

const MapPointIcon = styled(BaseIcon)`
  width: 100%;
  height: 26px;
  position: absolute;
  top: calc(50% + 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #3e3d4e; */
  color: #0e0c43;
`

const Container = styled(BaseContainer)``

const Inner = styled.div`
  padding-top: 180px;
  position: relative;

  ${media.tablet} {
    padding-top: 250px;
  }

  @media (max-height: 800px) {
    /* padding-top: calc(50vh - (800px * 0.5) + (800px * 0.3125)); */
  }
`

const Intro = styled.div`
  opacity: 0;
  margin-bottom: 100px;
  transition: opacity 0.5s;
  will-change: opacity;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    div${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  [data-event="-1"] & {
    opacity: 1;
  }

  @media (min-height: 1050px) {
    margin-bottom: calc(100vh - 950px);
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 70px;
  text-align: center;

  ${media.tablet} {
    margin-bottom: 100px;
  }
`

const Columns = styled.div`
  ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

const Column = styled.div`
  ${media.tablet} {
    width: calc(50% - 40px);
  }
`

const Paragraph = styled.p`
  white-space: pre-line;
`

const ScrollIndicatorWrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  text-align: center;
  margin-bottom: 50px;
  transition: 0.25s;
  transition-property: visibility, opacity;

  [data-event="-1"] & {
    visibility: visible;
    opacity: 1;
  }
`

const ScrollIndicator = styled.div`
  display: inline-block;
  padding: 0 25px;
  /* border: 1px solid #cfdbdb; */
  border: 1px solid rgba(0, 11, 33, 0.1);
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 50px;
  letter-spacing: 0.1em;
  /* color: ${(p) => p.theme.color.textLight}; */
  color: #3e3d4e;
`

const ScrollIndicatorIcon = styled(BaseIcon)`
  margin-left: -5px;
  margin-right: 10px;
  color: #46cad8;
`

const Timeline = styled.div`
  /* width: 100%; */
  /* height: 42vh; */
  position: relative;

  @media (max-height: 700px) {
    /* height: calc(25vh + (700px * 0.42) - (700px * 0.25)); */
  }
`

const Events = styled.div`
  width: 100%;
  padding-left: 100px;

  ${media.desktop} {
    padding-left: 140px;
  }

  @media (min-width: 1280px) {
    padding-left: ${fluidSizing(1280, 1350, 140, 170)};
  }

  @media (min-width: 1350px) {
    padding-left: 170px;
  }
`

const Event = styled.div`
  height: 100vh;
  /* max-height: 800px; */
  position: relative;
  transition: opacity 0.5s;

  &:not(.-active) {
    opacity: 0;
  }
`

const EventYear = styled.h3`
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 1;
  font-family: ${(p) => p.theme.font.secondary};
  color: ${(p) => p.theme.color.primary};

  ${media.tablet} {
    margin-bottom: 36px;
    font-size: 90px;
  }
`

const EventDetails = styled.div`
  max-width: 300px;

  em {
    font-weight: 600;
    color: #000;
  }

  br {
    display: none;
  }

  ${media.desktop} {
    max-width: 430px;

    br {
      display: inline-block;
    }

    em {
      position: relative;
      margin-left: 3px;
      margin-right: 3px;

      &::before {
        content: "";
        opacity: 0.2;
        width: 0;
        height: ${(p) => 17 * p.theme.baseLineHeight}px;
        position: absolute;
        left: -5px;
        bottom: -3px;
        background: linear-gradient(-20deg, #3ab6da, #50d9d6);
        background: #5fd1cf;
        transition: width 0.7s 0.5s ${(p) => p.theme.easing.easeOutQuart};
        will-change: width;

        ${Event}.-active & {
          width: calc(100% + 10px);
        }
      }

      span {
        position: relative;
        font-weight: 600;
        color: #000;
      }
    }
  }
`

const EventImageWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 300px;
  height: 400px;
  position: absolute;
  top: -200px;
  right: -20px;
  transform: translateY(60px);
  will-change: transform;
  transition: transform 0.1s linear;

  ${media.tablet} {
    display: flex;
  }
`

const EventImageInner = styled.span`
  display: inline-block;
  position: relative;
  transition: transform 0.5s ${(p) => p.theme.easing.easeOutQuart};

  ${Event}:not(.-active) & {
    transform: scale(0);
  }
`

const EventImage = styled.img`
  width: 200px;
  transform: rotate(-1deg);
  transform-origin: 50% 100%;
  color: #fff;

  ${(p) =>
    p.isPhoto &&
    css`
      width: 300px;
      right: -20px;
      border: 8px solid;
      box-shadow: 0 40px 80px rgba(0, 11, 33, 0.2);
    `}

  ${Event}:nth-child(4) & {
    width: 220px;
    transform: rotate(1deg);
  }

  ${Event}:nth-child(5) & {
    // width: 130px;
  }

  ${Event}:nth-child(6) & {
    width: 150px;
  }

  ${Event}:nth-child(7) & {
    width: 170px;
  }

  ${Event}:nth-child(10) & {
    width: 150px;
    transform: rotate(1deg);
  }

  ${Event}:nth-child(11) & {
    width: 150px;
  }

  ${Event}:nth-child(12) & {
    width: 180px;
    transform: rotate(1deg);
  }

  ${Event}:nth-child(13) & {
    width: 130px;
    margin-left: 60px;

    &:nth-child(2) {
      margin-top: 100px;
      margin-left: 80px;
    }
  }

  ${Event}:nth-child(14) & {
    width: 130px;
    margin-left: 60px;
    transform: rotate(1deg);

    &:nth-child(2) {
      margin-top: 100px;
      margin-left: 80px;
    }
  }
`

const EventImageFixed = styled(GatsbyImage)`
  width: 600px;
  position: static !important;
  transform: rotate(-1deg);
  transform-origin: 50% 100%;
  right: -20px;
  border: 8px solid;
  box-shadow: 0 40px 80px rgba(0, 11, 33, 0.2);
  color: #fff;
`

const EndEvent = styled.figure`
  position: relative;
`

const EndEventCaption = styled.figcaption`
  padding: 10px 20px;
  position: absolute;
  left: 0;
  bottom: 5px;
  background: #fff;
  border-radius: 0 3px 0 0;
  transform: rotate(-1deg);
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const TimelineBar = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  line-height: 50px;
  color: ${(p) => p.theme.color.textLight};
  font-family: ${(p) => p.theme.font.mono};
  will-change: transform;
  /* transition: opacity 0.5s, left 0.25s, transform 0.1s linear; */
  transition: opacity 0.5s, transform 0.1s linear;

  &::before {
    content: "";
    width: 1px;
    height: calc(100% - 40px);
    position: absolute;
    top: 0;
    left: 50%;
    background: rgba(0, 11, 33, 0.1);
  }

  [data-event="-1"] & {
    left: calc(50% - 16px);

    &::before {
      top: -40px;
    }
  }

  [data-event="18"] & {
    opacity: 0;
  }

  &.-fixed {
    position: fixed;
    top: 50vh;
    left: 50px;
  }

  ${media.desktopMedium} {
    &,
    &.-fixed {
      left: calc((100% - ${(p) => p.theme.containerWidth}px) / 2);
    }
  }
`

const TimelineBarPoint = styled.li`
  margin-bottom: 40px;
  position: relative;
  line-height: 30px;
  background: #fff;
  cursor: pointer;
  transition: color 0.15s;

  &::before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 2px;
    right: -18px;
    left: -18px;
    height: 26px;
    border: 1px solid #c6e1e1;
    border-radius: 13px;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: -20px;
    right: -17px;
    bottom: -20px;
    left: -17px;
  }

  &:hover {
    color: #076262;
  }

  &.-active {
    color: #076262;

    &::before {
      opacity: 1;
    }
  }
`

export default HistoryPage
